import { useAtomValue, useSetAtom } from 'jotai';
import { useState, useEffect } from 'react';
import { IoCloseOutline } from 'react-icons/io5'
import { selectedEmployeeIdAtom, selectedTimeAtom } from '../Booking/Booking_state';
import TextField from '../TextField/TextField';
import './SlideDrawer.css'

import { add } from 'date-fns';
import { cartAtom, showBookingDrawer, showBookingSuccessMessageAtom, showTermsAndConditions, storeDetailAtom } from '../App/App_state';
import { getAuth } from '@firebase/auth';

interface FormErrors {
  customerFirstName?: string;
  customerLastName?: string;
  customerEmail?: string;
  customerPhoneNumber?: string;
  Address?: string;
  pincode?: string;
  agreedTermsAndConditions?: string;
}

export default function SlideDrawer(props: {
  show: boolean
}) {
  // state to toggle between select services and Booking Page 
  const toggleShowBookingDrawer = useSetAtom(showBookingDrawer);
  const setShowBookingSuccessMessage = useSetAtom(showBookingSuccessMessageAtom)
  const setShowTermsAndConditions = useSetAtom(showTermsAndConditions)

  const storeDetail = useAtomValue(storeDetailAtom)
  const [chainId, storeId, storeType] = window.location.pathname.split('/').splice(1)
  const isRetailStore = storeType === 'retail-booking'

  // array of selected items
  const cart = useAtomValue(cartAtom)

  const selectedEmployeeId = useAtomValue(selectedEmployeeIdAtom)
  const selectedTime = useAtomValue(selectedTimeAtom)

  // form textfield state
  const [data, setData] = useState({
    customerFirstName: '',
    customerLastName: '',
    customerIsMale: true,
    customerEmail: '',
    customerPhoneNumber: '',
    remark: '',
    Address: '',
    pincode: '',
    agreedTermsAndConditions: false,
  });

  // Form field errors
  const [errors, setErrors] = useState<FormErrors>({});

  // Track fields that have been touched/interacted with
  const [touched, setTouched] = useState<{[key: string]: boolean}>({});

  const [showSpinner, setShowSpinner] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // Mark a field as touched when it loses focus
  const handleBlur = (field: string) => {
    setTouched({
      ...touched,
      [field]: true
    });
    validateField(field, data[field as keyof typeof data]);
  };

  // Validate individual field
  const validateField = (field: string, value: any) => {
    let newErrors: FormErrors = {...errors};
    
    switch(field) {
      case 'customerFirstName':
        if (!value || value.length < 3) {
          newErrors.customerFirstName = 'First name must be at least 3 characters';
        } else if (value.includes(' ')) {
          newErrors.customerFirstName = 'First name cannot contain spaces';
        } else {
          delete newErrors.customerFirstName;
        }
        break;
      
      case 'customerLastName':
        if (!value || value.length === 0) {
          newErrors.customerLastName = 'Last name is required';
        } else if (value.includes(' ')) {
          newErrors.customerLastName = 'Last name cannot contain spaces';
        } else {
          delete newErrors.customerLastName;
        }
        break;
      
      case 'customerEmail':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value || value.length < 3) {
          newErrors.customerEmail = 'Email address is required';
        } else if (!emailRegex.test(value)) {
          newErrors.customerEmail = 'Please enter a valid email address';
        } else {
          delete newErrors.customerEmail;
        }
        break;
      
      case 'customerPhoneNumber':
        if (!value || value.length === 0) {
          newErrors.customerPhoneNumber = 'Phone number is required';
        } else if (isNaN(parseFloat(value)) || value.length < 10 || value.length > 11) {
          newErrors.customerPhoneNumber = 'Please enter a valid phone number (10-11 digits)';
        } else {
          delete newErrors.customerPhoneNumber;
        }
        break;
      
      case 'Address':
        if (isRetailStore && (!value || value.length < 3)) {
          newErrors.Address = 'Address must be at least 3 characters';
        } else {
          delete newErrors.Address;
        }
        break;
      
      case 'pincode':
        if (isRetailStore && (!value || value.length < 6 || isNaN(parseFloat(value)))) {
          newErrors.pincode = 'Please enter a valid pincode';
        } else {
          delete newErrors.pincode;
        }
        break;
        
      case 'agreedTermsAndConditions':
        if (storeDetail?.termsAndConditions !== undefined && storeDetail?.termsAndConditions !== null && !value) {
          newErrors.agreedTermsAndConditions = 'You must agree to the terms and conditions';
        } else {
          delete newErrors.agreedTermsAndConditions;
        }
        break;
      
      default:
        break;
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form field change
  const handleChange = (field: string, value: any) => {
    const newData = { ...data, [field]: value };
    setData(newData);
    
    // If field has been touched, validate on change
    if (touched[field]) {
      validateField(field, value);
    }
  };

  // Check form validity whenever data changes
  useEffect(() => {
    // A simplified validation check that doesn't update state
    const checkFormValidity = () => {
      // Basic required field validation
      if (!data.customerFirstName || data.customerFirstName.length < 3 || data.customerFirstName.includes(' ')) {
        return false;
      }
      
      if (!data.customerLastName || data.customerLastName.length === 0 || data.customerLastName.includes(' ')) {
        return false;
      }
      
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!data.customerEmail || !emailRegex.test(data.customerEmail)) {
        return false;
      }
      
      if (!data.customerPhoneNumber || data.customerPhoneNumber.length < 10 || 
          data.customerPhoneNumber.length > 11 || isNaN(parseFloat(data.customerPhoneNumber))) {
        return false;
      }
      
      if (isRetailStore) {
        if (!data.Address || data.Address.length < 3) {
          return false;
        }
        
        if (!data.pincode || data.pincode.length < 6 || isNaN(parseFloat(data.pincode))) {
          return false;
        }
      }
      
      if ((storeDetail?.termsAndConditions !== undefined && storeDetail?.termsAndConditions !== null) && !data.agreedTermsAndConditions) {
        return false;
      }
      
      return true;
    };
    
    setIsFormValid(checkFormValidity());
  }, [data, storeDetail?.termsAndConditions, isRetailStore]);

  // Validate all fields at once for form submission
  const validateAllFields = () => {
    // Mark all fields as touched
    const allTouched: {[key: string]: boolean} = {};
    Object.keys(data).forEach(key => {
      allTouched[key] = true;
    });
    setTouched(allTouched);
    
    // Validate each field
    let isValid = true;
    let newErrors: FormErrors = {};
    
    if (!data.customerFirstName || data.customerFirstName.length < 3 || data.customerFirstName.includes(' ')) {
      newErrors.customerFirstName = data.customerFirstName.includes(' ') 
        ? 'First name cannot contain spaces' 
        : 'First name must be at least 3 characters';
      isValid = false;
    }
    
    if (!data.customerLastName || data.customerLastName.length === 0 || data.customerLastName.includes(' ')) {
      newErrors.customerLastName = data.customerLastName.includes(' ') 
        ? 'Last name cannot contain spaces' 
        : 'Last name is required';
      isValid = false;
    }
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!data.customerEmail || data.customerEmail.length < 3 || !emailRegex.test(data.customerEmail)) {
      newErrors.customerEmail = !emailRegex.test(data.customerEmail) 
        ? 'Please enter a valid email address' 
        : 'Email address is required';
      isValid = false;
    }
    
    if (!data.customerPhoneNumber || data.customerPhoneNumber.length < 10 || 
        data.customerPhoneNumber.length > 11 || isNaN(parseFloat(data.customerPhoneNumber))) {
      newErrors.customerPhoneNumber = 'Please enter a valid phone number (10-11 digits)';
      isValid = false;
    }
    
    if (isRetailStore && (!data.Address || data.Address.length < 3)) {
      newErrors.Address = 'Address must be at least 3 characters';
      isValid = false;
    }
    
    if (isRetailStore && (!data.pincode || data.pincode.length < 6 || isNaN(parseFloat(data.pincode)))) {
      newErrors.pincode = 'Please enter a valid pincode';
      isValid = false;
    }
    
    if ((storeDetail?.termsAndConditions !== undefined && storeDetail?.termsAndConditions !== null) && !data.agreedTermsAndConditions) {
      newErrors.agreedTermsAndConditions = 'You must agree to the terms and conditions';
      isValid = false;
    }
    
    setErrors(newErrors);
    return isValid;
  };

  return (
    <div className={props.show ? 'side-drawer open canvas' : 'side-drawer'}>

      <button aria-label='Close Drawer' onClick={() => toggleShowBookingDrawer((v) => !v)}>
        <IoCloseOutline size={42} color={'grey'} />
      </button>

      <h3>Enter Client Details</h3>
      <small>Enter Your Details to continue.</small>

      <TextField 
        label={'First Name'} 
        value={data.customerFirstName} 
        onChange={(e) => handleChange('customerFirstName', e.target.value)} 
        onBlur={() => handleBlur('customerFirstName')}
        error={touched.customerFirstName ? errors.customerFirstName : undefined}
      />
      
      <TextField 
        label={'Last Name'} 
        value={data.customerLastName} 
        onChange={(e) => handleChange('customerLastName', e.target.value)}
        onBlur={() => handleBlur('customerLastName')} 
        error={touched.customerLastName ? errors.customerLastName : undefined}
      />

      <div className="genderRadio">
        <p>Gender</p>

        <input
          type='checkbox'
          id='maleRadioButton'
          aria-labelledby='maleRadioButton'
          checked={data.customerIsMale}
          onChange={() => setData({
            ...data,
            customerIsMale: true,
          })} /> Male
        <label htmlFor='femaleRadioButton'></label>

        <input
          id='femaleRadioButton'
          type='checkbox'
          checked={data.customerIsMale === false}
          onChange={() => setData({
            ...data,
            customerIsMale: false,
          })} /> Female
        <label htmlFor='femaleRadioButton'></label>
      </div>

      <div style={{ height: '14px' }}></div>

      <TextField 
        label={'Email Address'} 
        value={data.customerEmail} 
        onChange={(e) => handleChange('customerEmail', e.target.value)}
        onBlur={() => handleBlur('customerEmail')} 
        error={touched.customerEmail ? errors.customerEmail : undefined}
      />
      
      <TextField 
        label={'Mobile Number'} 
        value={data.customerPhoneNumber} 
        onChange={(e) => handleChange('customerPhoneNumber', e.target.value)}
        onBlur={() => handleBlur('customerPhoneNumber')} 
        error={touched.customerPhoneNumber ? errors.customerPhoneNumber : undefined}
      />
      
      {isRetailStore && 
        <TextField 
          label={'Address'} 
          value={data.Address} 
          onChange={(e) => handleChange('Address', e.target.value)}
          onBlur={() => handleBlur('Address')} 
          error={touched.Address ? errors.Address : undefined}
        />
      }

      {isRetailStore && 
        <TextField 
          label={'Pincode'} 
          value={data.pincode} 
          onChange={(e) => handleChange('pincode', e.target.value)}
          onBlur={() => handleBlur('pincode')} 
          error={touched.pincode ? errors.pincode : undefined}
        />
      }

      <TextField 
        label={'Remark'} 
        value={data.remark ?? ''} 
        onChange={(e) => handleChange('remark', e.target.value)}
      />

      {isRetailStore && <p style={{ padding: '14px 0' }}> Cash on Delivery</p>}

      {
        storeDetail?.termsAndConditions !== undefined && storeDetail?.termsAndConditions !== null
          ?
          <div>
            <small style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              alignItems: 'center',
              marginBottom: '24px'
            }}>
              <input
                type='checkbox'
                id='termsAndConditionCheckBox'
                aria-labelledby='term & conditions'
                checked={data.agreedTermsAndConditions}
                onChange={() => {
                  const newValue = !data.agreedTermsAndConditions;
                  handleChange('agreedTermsAndConditions', newValue);
                  // Update touched state for terms checkbox
                  setTouched({...touched, agreedTermsAndConditions: true});
                }}
                aria-invalid={!!(touched.agreedTermsAndConditions && errors.agreedTermsAndConditions)}
              />
              <div style={{ width: '7px' }}></div>

              I agree to the

              <label htmlFor='termsAndConditionCheckBox'></label>

              <div style={{ width: '4px' }}></div>

              <button
                style={{ color: 'blue', width: 'unset' }}
                onClick={() => {
                  if (data.agreedTermsAndConditions === false) {
                    handleChange('agreedTermsAndConditions', true);
                    setTouched({...touched, agreedTermsAndConditions: true});
                  }
                  setShowTermsAndConditions(true);
                }}
              >
                terms & Conditions
              </button>
            </small>
            {touched.agreedTermsAndConditions && errors.agreedTermsAndConditions && (
              <div className="error-message" id="terms-error" style={{ marginTop: '-20px', marginBottom: '15px' }}>
                {errors.agreedTermsAndConditions}
              </div>
            )}
          </div>
          : <></>
      }

      <div style={{ height: '22px' }}></div>

      {
        showSpinner ?
          <div className='loaderSmall'></div>
          : <button aria-label='Add Salon Booking' onClick={async () => {

            if (showSpinner) {
              return;
            }
            
            // Validate all fields before submission
            const isValid = validateAllFields();
            if (!isValid) {
              // Scroll to the first error if validation fails
              const firstErrorElement = document.querySelector('[aria-invalid="true"]');
              if (firstErrorElement) {
                firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }
              return;
            }

            const bookingDocData = {
              customerFirstName: data.customerFirstName,
              customerLastName: data.customerLastName,
              customerEmail: data.customerEmail,
              customerPhoneNumber: data.customerPhoneNumber,
              agreedTermsAndConditions: data.agreedTermsAndConditions,
              customerIsMale: data.customerIsMale,

              remark: data.remark.length === 0 ? null : data.remark,

              storeId: storeDetail?.invoayId,

              ...(isRetailStore ? {
                source: 'Grocery App',

                datetime: (selectedTime ?? new Date()).toJSON(),

                address: data.Address,
                pincode: data.pincode,
                items: cart.map((item) => {
                  return {
                    invoayId: item.invoayId,
                    cost: item.cost,
                    qty: item.cartQty,
                  }
                }),

              } : {
                source: 'Booking App',
                employeeId: storeDetail!.employees!.find((employee) => employee.id === selectedEmployeeId)!.invoayId,

                datetimeFrom: selectedTime?.toJSON(),
                datetimeTo: add(selectedTime!, { minutes: cart.map((item) => (item.durationInMinutes ?? 0) * (item.cartQty ?? 1)).reduce((a, b) => a + b) }).toJSON(),

                items: cart.map((item) => {
                  return {
                    invoayId: item.invoayId,
                    cost: item.cost,
                    qty: item.cartQty,
                  }
                }),

              })
            }

            setShowSpinner(true)

            const authorization = await getAuth().currentUser?.getIdToken()

            if (authorization === undefined) {
              alert('unauthenticated')
              setShowSpinner(false)
              return;
            }

            //Get current domain from the URL
            let currentDomain = window.location.hostname;
            //Check if the current domain is localhost
            let isLocalhost = currentDomain === "localhost";
            //Set the API URL
            let apiUrl = isLocalhost ? "https://booking.invoay.com" : currentDomain;

            //Make API call to add booking in this way will prevent the CORS error
            fetch(apiUrl + "/addBooking", {
              method: 'POST',
              headers: new Headers([
                ["Content-Type", "application/json"],
                ["token", authorization!],
              ]),
              body: JSON.stringify({
                ...bookingDocData,
                firestoreReferenceId: isRetailStore
                  ? `chains/${chainId}/stores/${storeId}/bookings`
                  : `chains/${chainId}/stores/${storeId}/employees/${selectedEmployeeId}/bookings`,
              }),
            })
              .then(response => {
                if (response.status === 200 || response.status === 204) {
                  setShowBookingSuccessMessage(true)
                }
                setShowSpinner(false)
                return response.text();
              })
              .catch(error => {
                setShowSpinner(false)
                alert('Something Went Wrong');
              });

          }}>
            <div className={`canvasButton ${isFormValid ? 'canvasDark' : ''}`} 
                 style={isFormValid ? {} : { backgroundColor: 'grey' }}>
              {isRetailStore ? 'Checkout Now' : 'Book Now'}
            </div>
          </button>
      }
      <div style={{ height: '442px' }}></div>
    </div >
  )
}